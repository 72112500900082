import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocalStorageJwtService } from './local-storage-jwt.service';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private localStorage: LocalStorageJwtService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token: string;
    let apiKey: string;
    of(this.localStorage.getItem()).subscribe((t) => (token = t && t.accessToken));
    of(this.localStorage.getDecodedItem()).subscribe(
      (t) => (apiKey = t && t.idToken['custom:apiKey'])
    );
    if (token) {
      const header = {
        Authorization: `Bearer ${token}`
      };
      if (apiKey) {
        header['x-api-key'] = apiKey;
      }
      request = request.clone({
        setHeaders: header
      });
    }
    return next.handle(request);
  }
}
